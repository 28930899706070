<template>
  <div v-if="event">
    <LeerQREntrada
      v-if="mostrarModal"
      :key="modalKey"
      @hide="toggleModal(false)"
      @scan="buscarPorId"
    />
    <w-top-bar>{{ event.titulo }}</w-top-bar>

<button @click="buscarPorId({_id: '654b872dec129400111450de|0'})" class="btn btn-primary">buscarPorId</button>

    <div v-if="event" class="h-full bg-gray-200 relative">
      <!-- component -->
      <div class="flex mx-4 space-x-4">
        <div class="relative pt-2 mb-2 text-gray-600 flex-1">
          <input
            class="w-full h-10 text-sm bg-white border-2 border-gray-300 rounded-lg focus:outline-none"
            type="search"
            v-model="searchTerm"

            name="search"
            placeholder="Buscar por nombre, correo electrónico"
          />
        </div>
        <!-- Icono camara -->
        <div class="flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 cursor-pointer"
            @click="toggleModal(true)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
            />
          </svg>
        </div>
      </div>
      <div class="w-full">
        <div class="border-b border-gray-400 dark:border-gray-700 mb-4 mx-4">
          <ul
            class="flex flex-wrap -mb-px mt-2"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li class="mr-2" role="presentation">
              <button
                class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300"
                id="profile-tab"
                type="button"
                role="tab"
                @click="changeTab('pendientes')"
                :class="{
                  'border-b border-indigo-700': tabActive === 'pendientes',
                  'text-gray-800': tabActive === 'pendientes',
                }"
                aria-controls="profile"
                aria-selected="false"
              >
                Pendientes
                {{ totales.pendientes > 0 ? `(${totales.pendientes})` : "" }}
              </button>
            </li>
            <li class="mr-2" role="presentation">
              <button
                class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 active"
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                @click="changeTab('procesados')"
                :class="{
                  'border-b border-indigo-700': tabActive === 'procesados',
                  'text-gray-800': tabActive === 'procesados',
                }"
                role="tab"
                aria-controls="dashboard"
                aria-selected="true"
              >
                Procesados
                {{ totales.procesados > 0 ? `(${totales.procesados})` : "" }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="flex flex-col items-center justify-center h-48"
        v-if="compras && compras.length === 0 && !loading"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-12 h-12 text-base-300"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="my-2 font-normal text-gray-700">No hay resultados</div>
      </div>
      <div
        v-else
        class="overflow-hidden shadow bshipment-b bshipment-gray-200 sm:rounded-lg"
      >
        <div class="w-full">
          <div
            class="bg-white border border-gray-400 overflow-x-scroll mx-4 rounded my-6"
          >
            <table
              :class="{
                'opacity-50 cursor-not-allowed': busy,
              }"
              class="min-w-max w-full table-auto"
            >
              <thead>
                <tr
                  class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                >
                  <th class="py-3 px-6 font-medium text-left">Nombre</th>

                  <th class="py-3 px-6 font-medium text-center">Acciones</th>
                </tr>
              </thead>
              <tbody class="text-sm font-light">
                <tr
                  v-for="(item, index) in comprasFiltradas"
                  :key="item._id + index"
                  class="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td class="py-3 px-6 text-left whitespace-nowrap">
                    <div
                      class="text-md leading-5 font-medium text-gray-800 mb-2"
                    >
                      {{ item._id }}
                    </div>
                    <div class="text-xs leading-5 md:text-sm">
                      {{ item.entradas.nombre | capitalizar }}
                      {{ item.entradas.apellido | capitalizar }}
                    </div>

                    <div class="text-xs leading-5 text-gray-500 md:text-sm">
                      {{ item.entradas.correo }}
                    </div>
                    <div
                      v-if="item.entradas.correo != item.email"
                      class="text-xs leading-5 text-gray-500 md:text-sm flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-3 h-3 mr-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                        />
                      </svg>

                      {{ item.email }}
                    </div>
                  </td>
                  <td class="py-3 px-6 text-right">
                    <div class="flex justify-end space-x-2 items-center">
                      <svg
                        v-if="item.entradas.destacar"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-orange-700 cursor-pointer"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                        />
                      </svg>

                      <svg
                        v-if="tabActive === 'pendientes'"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        @click="validarEntrada(item, index)"
                        v-tooltip="'Validar entrada'"
                        class="w-6 h-6 text-green-700 cursor-pointer"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <svg
                        v-if="tabActive === 'procesados'"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-red-700 cursor-pointer"
                        v-tooltip="'Cancelar validación'"
                        @click="cancelarValidacion(item, index)"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import LeerQREntrada from "@/components/nuevo/LeerQREntrada.vue";
export default {
  layout: "default",
  components: {
    LeerQREntrada,
  },
  data() {
    return {
      mostrarModal: false,
      modalKey: 0,
      event: null,
      compras: [],
      participantes: null,
      validos: null,
      canjeados: null,
      searchTerm: "",
      downloading: false,
      tabActive: "pendientes",
      totales: {
        pendientes: 0,
        procesados: 0,
      },
      busy: true,
    };
  },
  async created() {
    this.refresh();

    this.event = await this.obtenerEvento(this.$route.params.id);
    console.log("this.event", this.event);
  },
  computed: {
    comprasFiltradas() {
      console.log("comprasFiltradas", this.searchTerm);
      const searchTermLower = this.searchTerm.toLowerCase();

      if(!this.compras) return [];

      let compras = this.compras.filter((compra) => !compra.entradas.validado);


      console.log("Buscar por ID", this.searchTerm.includes("|"))

      // Si tiene este formato: 64f483b19651010011995848|0'
      // Es una busqueda por ID, y el 0 es el indice que hay que destacar.

      
      if (this.searchTerm.includes("|")) {
        console.log("searchTerm", this.searchTerm);
        const [id, index] = this.searchTerm.split("|");
        const compras = this.compras.filter((compra) => compra._id.includes(id));
        console.log("compras", compras)
        if (!compras) return;
        console.log("compras", compras);
        // Destacar el item con el indice
        if (compras[index]) compras[index].entradas.destacar = true;
        return compras;
        // .sort((a, b) => {
        //   if (a.entradas.destacar) return -1;
        //   if (b.entradas.destacar) return 1;
        //   return 0;
        // });
      }

      return compras.filter((compra) => {
        
        const join =
          compra.entradas?._id +
          " " +
          compra.entradas?.nombre?.toLowerCase() +
          " " +
          compra.entradas?.apellido?.toLowerCase() +
          " " +
          compra.entradas?.correo?.toLowerCase();
        +" ";
        compra.email.toLowerCase();

        return join.includes(searchTermLower);
      });
    },
    currency() {
      return this.event.preferredCurrency;
    },
    totalVentas() {
      if (!this.compras) return;
      return this.compras.reduce((total, compra) => {
        total += compra.entradas.reduce((acc, entrada) => {
          if (!entrada.precio) {
            return acc;
          }
          return acc + entrada.precio[this.currency];
        }, 0);
        return total;
      }, 0);
    },
    totalEntradas() {
      if (!this.ventas) return 0;
      return this.ventas.reduce((acc, venta) => {
        acc += venta.total;
        return acc;
      }, 0);
    },
    totalCupos() {
      if (!this.ventas) return 0;
      return this.ventas.reduce((acc, venta) => {
        acc += venta.cupo;
        return acc;
      }, 0);
    },
  },
  methods: {
    ...mapActions("participantes", {
      obtenerParticipantes: "find",
      actualizarPago: "patch",
    }),
    ...mapActions("eventos", {
      obtenerEvento: "get",
    }),
    ...mapActions("descargar-participantes", {
      descargar: "get",
    }),
    buscarPorId(data) {
      let tmp = data._id;
      this.searchTerm = tmp.toString();
    },
    toggleModal(value) {
      this.mostrarModal = value;
      this.modalKey++;
    },

    async cancelarValidacion(item, index) {
      console.log(item, index);
      this.busy = index;
      let id = item._id;
      // Si tiene - lo quitamos
      if (id.includes("-")) {
        id = id.split("-")[0];
      }

      await this.actualizarPago([
        id,
        {
          item: item,
          estadoEnEvento: "cancelarValidacion",
          index: index,
        },
      ]);
      this.$toast.success("La entrada se cancelo correctamente");

      // Quitar de la lista
      // Agregar la propiedad validado al item
      this.compras[index].entradas.validado = true;
      await this.refresh();
      // this.compras.splice(index, 1);
      this.busy = false;
    },

    async validarEntrada(item, index) {
      console.log(item, index);
      this.busy = index;
      let id = item._id;

      // Si tiene - lo quitamos
      if (id.includes("-")) {
        id = id.split("-")[0];
      }

      await this.actualizarPago([
        id,
        {
          item: item,
          estadoEnEvento: "procesado",
          index: index,
        },
      ]);
      this.$toast.success("Entrada validada");

      // Quitar de la lista
      // Agregar la propiedad validado al item
      this.compras[index].entradas.validado = true;
      await this.refresh();
      // this.compras.splice(index, 1);
      this.busy = false;
    },
    changeTab(tab) {
      this.tabActive = tab;
      this.refresh();
    },
    async refresh() {
      this.busy = true;
      let extra = {};
      if (this.tabActive === "procesados") {
        extra = {
          "entradas.estadoEnEvento": "procesado",
        };
      } else {
        extra = {
          "entradas.estadoEnEvento": {
            $ne: "procesado",
          },
        };
      }

      let res = await this.obtenerParticipantes({
        query: {
          "evento._id": this.$route.params.id,
          status: "pago",
          ...extra,
        },
      });
      this.compras = res;

      if (this.tabActive === "pendientes")
        this.totales.pendientes = this.compras.length;
      if (this.tabActive === "procesados")
        this.totales.procesados = this.compras.length;

      this.busy = false;
    },
    async descargarParticipantes() {
      this.downloading = true;
      const id = this.$route.params.id;
      const res = await this.descargar(id);
      const participantes = res.participantes;

      // Convertir a CSV y descargar
      const csv = participantes
        .map((participante) => {
          // Nombre y apellido, castear a mayúscula la primera letra. Email todo en minúscula.

          const nombre = participante.nombre
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const apellido = participante.apellido
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const correo = participante.correo
            ? participante.correo.toLowerCase()
            : "";

          return [nombre, apellido, correo, participante.nombreEntrada].join(
            ","
          );
        })
        .join("\n");

      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      link.download = "participantes-" + id + ".csv";
      link.click();

      setTimeout(() => {
        this.downloading = false;
      }, 500);
    },
    async descargarEntradas(id) {
      // https://api.orsai.org/descargar-entradas/64de65e0fd74940010598fab
      const link = document.createElement("a");
      link.href = `https://api.orsai.org/descargar-entradas/${id}`;
      link.download = "entradas" + id + ".pdf";
      link.click();
    },
  },
  filters: {
    formatDate(value) {
      return [
        moment(value)
          .format("dddd")
          .match(/^[a-z]/i)[0]
          .toUpperCase() + moment(value).format("dddd").slice(1),
        moment(value).format("D"),
        moment(value)
          .format("MMMM")
          .match(/^[a-z]/i)[0]
          .toUpperCase() + moment(value).format("MMMM").slice(1),
        moment(value).format("YYYY"),
        moment(value).format("HH:mm"),
      ].join(" ");
    },
    capitalizar(value) {
      if (!value) return;
      return value
        .toLowerCase()
        .split(" ")
        .map((word) => {
          if (!word) return;
          return word[0].toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    parseKey(value) {
      // Capitalizar primer letra y reemplazar guiones por espacios
      return value[0].toUpperCase() + value.slice(1).replace(/-/g, " ");
    },
    tipoEducacion(value) {
      return [
        { value: "profe", label: "Soy profe de Lengua y literatura" },
        { value: "otro", label: "Soy profe de otra materia" },
        { value: "estudiante", label: "Soy estudiante de profesorado" },
        { value: "comunidad", label: "Solo soy de Comunidad Orsai" },
      ].find((item) => item.value === value).label;
    },
  },
};
</script>
<style>
/* Only desktop */
@media (min-width: 768px) {
  .md-w-128 {
    width: 27rem !important;
  }
}
</style>